// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-prestation-js": () => import("./../src/templates/prestation.js" /* webpackChunkName: "component---src-templates-prestation-js" */),
  "component---src-pages-planning-js": () => import("./../src/pages/planning.js" /* webpackChunkName: "component---src-pages-planning-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amelie-geoffrey-js": () => import("./../src/pages/amelie-geoffrey.js" /* webpackChunkName: "component---src-pages-amelie-geoffrey-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cours-index-js": () => import("./../src/pages/cours/index.js" /* webpackChunkName: "component---src-pages-cours-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merci-js": () => import("./../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-mes-reservations-js": () => import("./../src/pages/mes-reservations.js" /* webpackChunkName: "component---src-pages-mes-reservations-js" */),
  "component---src-pages-reservation-js": () => import("./../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-tarifs-js": () => import("./../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */)
}

